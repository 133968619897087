export const GET_WEBPAGES_STATED = "GET_WEBPAGES_STATED";
export const GET_WEBPAGES = "GET_WEBPAGES";
export const GET_WEBPAGES_ENDED = "GET_WEBPAGES_ENDED";
export const ADD_WEBPAGE_STATED = "ADD_WEBPAGE_STARTED";
export const ADD_WEBPAGE = "ADD_WEBPAGE";
export const ADD_WEBPAGE_ENDED = "ADD_WEBPAGE_ENDED";
export const EDIT_WEBPAGE_STATED = "EDIT_WEBPAGE_STATED";
export const EDIT_WEBPAGE = "EDIT_WEBPAGE";
export const EDIT_WEBPAGE_ENDED = "EDIT_WEBPAGE_ENDED";
export const GET_WEBPAGE = "GET_WEBPAGE";
export const GET_WEBPAGE_STATED = "GET_WEBPAGE_STATED";
export const GET_WEBPAGE_ENDED = "GET_WEBPAGE_ENDED";
export const RESET_WEBPAGE = "RESET_WEBPAGE";
export const ERROR_WEBPAGE = "ERROR_WEBPAGE";
export const GET_ALL_WEBPAGES_STATED = "GET_ALL_WEBPAGES_STATED";
export const GET_ALL_WEBPAGES = "GET_ALL_WEBPAGES";
export const GET_ALL_WEBPAGES_ENDED = "GET_ALL_WEBPAGES_ENDED";
export const GET_SIMILAR_WEBPAGE = "GET_SIMILAR_WEBPAGE";
export const GET_SIMILAR_WEBPAGE_STATED = "GET_SIMILAR_WEBPAGE_STATED";
export const GET_SIMILAR_WEBPAGE_ENDED = "GET_SIMILAR_WEBPAGE_ENDED";
