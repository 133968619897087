import React from 'react'
import { URI } from "../../domain/constant";
import { useSelectAllBanner } from "../../shared/hooks/UseBanner";
import { Link } from "react-router-dom";

function Banner() {
  const [data] = useSelectAllBanner();
  const { all_banners, all_banners_loading } = data;

  return (
    <div>
      <section className="hero-section-two rel z-1 bg-green">
        {all_banners && all_banners.map((item) => {
          return (
            <>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="hero-content-two mt-225 mb-170">
                      <span className="sub-title wow fadeInUp delay-0-2s">{item.name}</span>
                      <h1 className="wow fadeInUp delay-0-4s">
                        {item.short_description}
                      </h1>
                      <div className="hero-btn mt-15 wow fadeInUp delay-0-8s">
                        <Link className="theme-btn mt-15 style-four" to="/contact-us">Learn more <i className="fas fa-long-arrow-alt-right" /></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-right-image">
                <img src="assets/images/hero/hero-circle.png" alt="Hero" />
                <img className="wow fadeInUp delay-0-2s" style={{ width: "100%" }} src={`${URI}${item.image}`} alt="Heo Right" />
              </div>
              <div className="hero-green-lear">
                <img src="assets/images/shapes/hero-green-leaf.png" alt="Leaf" />
              </div>
              <div className="hero-three-shapes">
                <img src="assets/images/shapes/hero-three-shapes.png" alt="Shape" />
              </div>
            </>
          )

        })}


      </section>
    </div>
  )
}

export default Banner