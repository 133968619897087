export const GET_FAQS_STATED = "GET_FAQS_STATED";
export const GET_FAQS = "GET_FAQS";
export const GET_FAQS_ENDED = "GET_FAQS_ENDED";
export const ADD_FAQ_STATED = "ADD_FAQ_STARTED";
export const ADD_FAQ = "ADD_FAQ";
export const ADD_FAQ_ENDED = "ADD_FAQ_ENDED";
export const EDIT_FAQ_STATED = "EDIT_FAQ_STATED";
export const EDIT_FAQ = "EDIT_FAQ";
export const EDIT_FAQ_ENDED = "EDIT_FAQ_ENDED";
export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_STATED = "GET_FAQ_STATED";
export const GET_FAQ_ENDED = "GET_FAQ_ENDED";
export const RESET_FAQ = "RESET_FAQ";
export const ERROR_FAQ = "ERROR_FAQ";
export const GET_ALL_FAQS_STATED = "GET_ALL_FAQS_STATED";
export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const GET_ALL_FAQS_ENDED = "GET_ALL_FAQS_ENDED";
export const GET_SIMILAR_FAQ = "GET_SIMILAR_FAQ";
export const GET_SIMILAR_FAQ_STATED = "GET_SIMILAR_FAQ_STATED";
export const GET_SIMILAR_FAQ_ENDED = "GET_SIMILAR_FAQ_ENDED";
