import React, { useState } from 'react'
import { Formik, Form } from "formik";
import { TextArea, TextInput, SelectBox } from "../../components/Form/Form";
import * as Yup from "yup";
import { useCreateContact } from "../../shared/hooks/UseContact";

function ContactForm() {
    const [data, addData] = useCreateContact();
    const [showGreeting, setSetshowGreeting] = useState(false)


    const addContact = ({ values }) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://rejuveaestheticclinic.com/mailer/mail.php?name=${values.name}&phone=${values.phone}&email=${values.email}&message=${values.message}`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    return (
        <div>
            <div className="contact-form-area py-150 rpy-100">
                <div className="container">
                    <div className="contact-form-wrap wow fadeInUp delay-0-2s">
                        <div className="section-title mb-45 text-center">
                            <h2>Send  Us  Message</h2>
                        </div>
                        {
                            showGreeting ? (
                                <>
                                    <div className="modal-close-icon thankyou-modal-close">
                                    </div>
                                    <div className="success-message-box">

                                        <div className="success-message">
                                            <h4>Thankyou!</h4>
                                            <p>Thankyou for Successfuly filling the form. Our Team will contact you regarding the same</p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <Formik
                                    initialValues={{
                                        name: "",
                                        email: "",
                                        phone: "",
                                        message: "",
                                    }}
                                    validationSchema={Yup.object({
                                        name: Yup.string().required("Required"),
                                        email: Yup.string().email('Invalid email').required('Required'),
                                        phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid').min(10, 'Must be exactly 10 digit').max(10, 'Must be exactly 10 digit').required("Required"),
                                        message: Yup.string().required("Required"),
                                    })}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        addData(values);
                                        addContact({ values });
                                        setSetshowGreeting(!showGreeting)
                                        resetForm();
                                        setSubmitting(false);
                                    }}
                                >
                                    {(formik) => {
                                        console.log(formik);
                                        return (
                                            <Form className="booking-from">
                                                <div className="row clearfix">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <TextInput
                                                                type="text"
                                                                id="name"
                                                                name="name"
                                                                className="form-control"
                                                                defaultValue placeholder="Full Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <TextInput
                                                                type="text"
                                                                id="email"
                                                                name="email"
                                                                className="form-control"
                                                                defaultValue placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <TextInput
                                                                type="text"
                                                                id="phone"
                                                                name="phone"
                                                                className="form-control"
                                                                defaultValue placeholder="Phone" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <TextArea
                                                                type="text"
                                                                id="message"
                                                                name="message"
                                                                className="form-control"
                                                                defaultValue placeholder="Message"
                                                                col={12}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-body">
                                                    <div className="my-3 border-bottom" />
                                                    <div className="mb-0">
                                                        <button
                                                            type="submit"
                                                            className="theme-btn w-50per style-three"
                                                        >
                                                            Send Message
                                                            <i className="fas fa-long-arrow-alt-right" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm