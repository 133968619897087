import React, { useState } from "react";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import Banner from "../../components/banner/Banner";
import WhatWeOffer from "../../components/whatweoffer/WhatWeOffer";
import HomeAbout from "../../components/home_about/HomeAbout";
import Team from "../../components/team/Team";
import WhyUs from "../../components/why_us/WhyUs";
import MakeAppointment from "../../components/make_appointment/MakeAppointment";
import Testimonial from "../../components/testimonial/Testimonial";
import Blog from "../../components/blog/Blog";
import { useGetWebpage } from '../../shared/hooks/UseWebpage'
import { URI } from "../../domain/constant";
import renderHTML from "react-render-html";
import Faq from "../../components/faq/Faq";
import { Helmet } from "react-helmet";


function Home() {
  const [webpage_data] = useGetWebpage()
  const { webpages, loading } = webpage_data;

  console.log("WEBPAGES-", webpages);
  return (
    <>
      <div className="page-wrapper">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Best Aesthetic Clinic in Guwahati | Skin Rejuvenation Clinic in Guwahati - Rejuve Aesthetics</title>
          <meta name="description" content="Rejuve Aesthetics is the Best Skin Rejuvenation Clinic in Guwahati. Dr. Sharadi Shreemoyee is an expert Dermatologist for Laser Treatment and Skin Treatment. Contact Us Today!"
          />
        </Helmet>
        <Banner />
        <WhatWeOffer />
        <HomeAbout
          heading={webpages && webpages.who_we_are_heading}
          image={`${URI}${webpages && webpages.who_we_are_image}`}
          content={webpages && webpages.who_we_are_content && renderHTML(webpages.who_we_are_content)}
          content_2={webpages && webpages.who_we_are_content_2 && renderHTML(webpages.who_we_are_content_2)}
        />
        <Team
          name={webpages && webpages.doctor_name}
          image={`${URI}${webpages && webpages.doctor_image}`}
          job_role={webpages && webpages.doctor_job_role}
          content={webpages && webpages.doctor_content && renderHTML(webpages.doctor_content)}
        />
        <WhyUs />
        <MakeAppointment />
        <Testimonial />
        <Blog />
        <Faq />
        <Footer />
      </div>
    </>
  );
}

export default Home;
