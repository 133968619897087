import React from 'react'
import { Link } from 'react-router-dom'

function HomeAbout({ image, heading, content, content_2 }) {
  return (
    <div>
      <section className="about-section-two rel  rpy-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-left rel pt-10 rmb-55 wow fadeInLeft delay-0-2s">
                <img src={image} alt="About" />
                <div className="circle-image">
                  <img src="assets/images/shapes/about-circle.png" alt="Circle" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content wow fadeInRight delay-0-2s">
                <div className="section-title mb-30">
                  <span className="bg-text">about</span>
                  <span className="sub-title">Who We Are</span>
                  <h2>{heading}</h2>
                </div>
                <p>
                  {content}
                </p>
                <i>{content_2}</i>
                <div className="about-btn mt-30">
                  <Link className="theme-btn style-three" to="/contact-us">read more <i className="fas fa-long-arrow-alt-right" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-right-leaf">
          <img src="assets/images/shapes/about-right-leaf.png" alt="About Leaf" />
        </div>
      </section>
    </div>
  )
}

export default HomeAbout