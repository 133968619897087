import React from 'react'
import BlogCard from './BlogCard'
import { URI } from "../../domain/constant";
import { useSelectAllBlog } from "../../shared/hooks/UseBlog";
import { Link } from "react-router-dom";

function Blog() {
  const [data] = useSelectAllBlog();
  const { all_blogs, all_blogs_loading } = data;
  return (
    <div>
      <section className="blog-section rel z-1 pt-140 rpt-90 mb-95 rmb-45">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9 col-md-10">
              <div className="section-title text-center mb-65">
                <span className="bg-text">Blog</span>
                <span className="sub-title">News &amp; Blog</span>
                <h2>Latest News &amp; Blog</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {
              all_blogs && all_blogs.map((item, i) => {
                if (i < 3) {
                  return (
                    <div className='col-xl-4 col-md-6'>
                      <Link to={`/blog/${item.slug}`}>
                        <BlogCard title={item.title} image={`${URI}${item.image}`} category={item.category && item.category.name} />
                      </Link>
                    </div>
                  )
                }

              })
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default Blog